import axios from 'axios'
import { useRouter } from 'next/router'
import qs from 'qs'
import React, { FormEventHandler, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import toast from 'react-hot-toast'

import { useTrackClick } from '@hooks/analytics/useTrackClick'
import logger from '@lib/logger'
import { isServer } from '@lib/runtime-environment'
import UrlHelper from '@lib/UrlHelper'
import { errorToHelpfulMessage } from '@models/APIErrorResponse'
import { setLoginModalInnerUI } from '@redux/slices/modals/loginModalSlice'
import { useAppDispatch } from '@redux/store/store'

const SUBMISSION_TIMEOUT = 2000

type AuthLinkName = 'facebookAuth' | 'twitterAuth' | 'googleAuth' | 'appleAuth'

export const AuthLink = ({
  name,
  children,
  returnUrl,
  buttonClass,
  onClick,
}: {
  name: string
  children: (isSubmitting: boolean) => ReactNode
  returnUrl?: string
  buttonClass?: string
  onClick?: () => void
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const formRef = useRef<HTMLFormElement | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const trackClick = useTrackClick()
  const authLinkName = `${name.toLowerCase()}Auth` as AuthLinkName
  const { asPath } = useRouter()

  const currentPath = useMemo(() => {
    if (isServer()) return asPath
    return window.location.href.replace(window.location.origin, '')
  }, [asPath])

  const href = useMemo(() => {
    const returnPath = `${UrlHelper.asRelativePath(returnUrl) ?? currentPath}`
    return `/auth/${name.toLowerCase().split(/\W/).pop()}${qs.stringify({ r: returnPath }, { addQueryPrefix: true })}`
  }, [currentPath, name, returnUrl])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null

    if (isSubmitting) {
      timeoutId = setTimeout(() => {
        setIsSubmitting(false)
      }, SUBMISSION_TIMEOUT)
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [isSubmitting])

  const submitWithToken = useCallback(
    async (recaptchaToken: string) => {
      if (!formRef.current) {
        setIsSubmitting(false)
        return
      }

      try {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'recaptchaToken'
        input.value = recaptchaToken
        formRef.current.appendChild(input)
        formRef.current.submit()
      } catch (error) {
        logger.error('Error on reCAPTCHA submit', error)
        toast.error('Sorry, something went wrong. Please try again.')
        setIsSubmitting(false)
      }
    },
    [formRef]
  )

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async event => {
      event.preventDefault()
      setIsSubmitting(true)
      onClick?.()

      try {
        if (!executeRecaptcha) return logger.error('reCAPTCHA not available')
        const token = await executeRecaptcha('login')
        await submitWithToken(token)
      } catch (error) {
        setIsSubmitting(false)
        logger.error('Error on reCAPTCHA Enterprise.', error)
        toast.error('Unable to verify. Please try again.')
      }
    },
    [executeRecaptcha, submitWithToken, onClick]
  )

  return (
    <form ref={formRef} action={href} method="post" onSubmit={handleFormSubmit}>
      <button
        type="submit"
        disabled={isSubmitting}
        onClick={() => trackClick(authLinkName)}
        className={`btn btn-square flex w-full cursor-pointer items-center space-x-4 p-4 focus:outline-none ${buttonClass}`}
      >
        {children(isSubmitting)}
      </button>
    </form>
  )
}

export const EmailAuthInput = ({
  setErrorMessage,
}: {
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
  const router = useRouter()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const dispatch = useAppDispatch()
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false)
  const emailRegex = useMemo(() => /^[^\s@]+@[^\s@]+\.[^\s@]+$/, [])

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(undefined)
    setIsEmailValid(emailRegex.test(event.target.value))
  }

  useEffect(() => {
    if (router.query.email && !Array.isArray(router.query.email)) setIsEmailValid(emailRegex.test(router.query.email))
  }, [emailRegex, router.query.email])

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async event => {
      event.preventDefault()

      const form = event.currentTarget
      const email = (form.elements.namedItem('email') as HTMLInputElement).value
      const returnUrl = (router.query.r as string) || router.asPath

      try {
        if (!executeRecaptcha) return logger.error('reCAPTCHA not available')
        const recaptchaToken = await executeRecaptcha('login')
        await axios
          .post(
            '/api/v0.1/auth/email/login',
            {
              recaptchaToken,
              email,
              returnUrl,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(() => dispatch(setLoginModalInnerUI('emailSubmitted')))
          .catch(error => {
            setErrorMessage(errorToHelpfulMessage(error) + '. Please try again.')
            logger.error('Error on reCAPTCHA submit', error)
          })
      } catch (error) {
        logger.error('Error on reCAPTCHA Enterprise.', error)
        toast.error('Unable to verify. Please try again.')
      }
    },
    [dispatch, setErrorMessage, executeRecaptcha, router.query.r, router.asPath]
  )

  return (
    <form className="flex space-x-3" onSubmit={handleFormSubmit}>
      <input
        name="email"
        className="w-full cursor-pointer rounded-lg border-2 p-4 text-sm focus:border-accent focus:ring-0"
        placeholder="Email address"
        type="text"
        onChange={handleEmailChange}
        defaultValue={router.query.email}
      />
      <input
        type="submit"
        disabled={!isEmailValid}
        className="btn btn-accent cursor-pointer self-center disabled:cursor-default disabled:bg-primary/20 disabled:text-menu/40 disabled:hover:scale-100 light:disabled:bg-contrast/10"
        value="Continue"
      />
    </form>
  )
}
